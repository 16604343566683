<template>
  <div>
    <v-skeleton-loader
      v-bind="attrs"
      type="image, article"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "Skeleton",
  data() {
    return {
      attrs: {
        class: "mb-6",
        boilerplate: false,
        elevation: 0,
      },
    };
  },
};
</script>

<style></style>
